<template>
    <div>
        <!-- about pd -->
        <div class="p-3">
            <h3>O projektu Prleška doživetja</h3>
            <br>
            <h5>Naslov operacije</h5>
            Spletna platforma za načrtovanje turističnih doživetij Prlekije
            <br><br>
            <h5>Akronim operacije</h5>
            Prleška doživetja
            <br>
            <br>
            <h5>Namen in cilji operacije</h5>
            Operacija Prleška doživetja prispeva k uresničevanju ukrepa Bogatitev lokalne turistične ponudbe in s tem k
            izboljšanju pogojev za učinkovito delovanje lokalne ekonomije.
            <br>
            <br>
            <h5>Specifični cilj operacije</h5>
            Spodbujanje in povezovanje lokalne ekonomije s poudarkom na turizmu.
            <br>
            <br>
            <h5>Aktivnosti operacije</h5>
            Izdelava spletnega orodja za oblikovanje turističnih doživetij po Prlekiji ter izdelava posebnih modulov za:
            • predstavitev turističnih atrakcij na posameznih točkah (kmetijah, mestih, muzejih ...),
            • namenski modul za oblikovanje doživetja (povezava teh točk) z elementi igrifikacije,
            • "booking" doživetja za kupce
            ter postavitev na strežnik in vzdrževanje spletne platforme.
            Operacija uvaja inovativni pristop k oblikovanju turistične ponudbe (samostojno sestavljanje lokalnih
            turističnih atrakcij v doživetje, tako s strani ponudnikov kot s strani turistov) ter vključitev elementov
            igrifikacije: s sistemom interaktivnih vprašanj, nalog in iger ter nagrajevanj se bo spodbujalo zanimanje in
            ohranjala pozornost obiskovalcev/turistov/uporabnikov doživetja.
            <br>
            <br>
            <h5>Kazalniki operacije</h5>
            so predvsem nova storitev, nova mreža partnerstev, vključeni udeleženci ter izvedeni dve pilotni doživetji.
            <br>
            <br>
            <h5>Finančna podpora</h5>
            Naložbo sofinancirata Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj.
            Povezava na spletno stran evropske kohezijske politike Slovenije: <a href="http://www.eu-skladi.si"
                                                                                 target="_blank">www.eu-skladi.si</a>.
            Povezava na spletno stran LAS Prlekija: <a href="http://las-prlekija.com/" target="_blank">http://las-prlekija.com/</a>.
            <br>
            <br>
            <h5>Rezultati operacije</h5>
            Spletna platforma Prleška doživetja povezuje posamezne turistom zanimive točke v skupna doživetja. Elementi
            igrifikacije na zabaven način preko iskanja zakladov, kvizov, spretnosti in iger, zastavljenih na podlagi
            starih kmečkih opravil in običajev, legend in krajevnih zgodb obiskovalca seznanjajo z naravno in kulturno
            dediščino in tradicijo Prlekije. Doživetja s kolesarskimi in peš potmi po hribčkih Prlekije ter
            osredotočanje ponudbe na manjše skupine, družine in pare pa prispevajo svoj delež k ohranjanju narave in
            naravnih virov.
        </div>
        <hr>
        <!-- about pnd -->
        <div class="p-3">
            <h3>O projektu Prleška spletna tržnica</h3>
            <br>
            <h5>Naslov operacije </h5>
            Organizacija neposredne spletne prodaje kmetijskih proizvodov Prlekije
            <br><br>
            <h5>Akronim operacije</h5>
            <b>Prleška spletna tržnica</b>
            <br>
            <br>
            <h5>Namen in cilji operacije</h5>
            Operacija Prleška spletna tržnica prispeva k uresničevanju ukrepa vzpostavljanja kratkih oskrbnih verig in s
            tem
            k učinkovitejšemu delovanju lokalne ekonomije.
            <br>
            <br>
            <h5>Specifični cilj operacije</h5> Povečevanje ponudbe lokalnih proizvodov in storitev v lokalnem okolju,
            končni cilj pa povečevanje samooskrbe in zmanjšanje uvoza pridelkov iz tujine.
            <br>
            <br>
            <h5>Aktivnosti operacije</h5>
            Izdelava spletnega orodja za neposredno prodajo prleških pridelkov in izdelkov,
            izobraževanja in kulinarični večeri z degustacijami dobrot lokalnih ponudnikov, vzpostavitev mreže
            ponudnikov in
            odjemalcev, promocija ponudbe prleških kmetij ter koordinacija
            neposredne spletne prodaje kmetijskih proizvodov Prlekije in njihove dostave. S tem se bo povečala
            neposredna
            prodaja končnim kupcem, ugoden bo vpliv na okolje z ohranjanjem kratkih oskrbnih verig ter socialni učinek
            vključevanja medgeneracijskih centrov v delavnice. Ciljne skupine so ponudniki, kupci in ranljive skupine
            (starejši in mlajši) z območja Prlekije.
            <br><br>
            <h5>Kazalniki operacije</h5> so predvsem ohranjanje delovnih mest, nova storitev, nova
            mreža partnerstev, vključeni udeleženci ter izvedene kulinarične delavnice.
            <br><br>
            <h5>Finančna podpora</h5>
            Naložbo sofinancirata Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj.
            <br>
            Povezava na spletno stran evropske kohezijske politike Slovenije: <a
            href="http://www.eu-skladi.si" target="_blank">www.eu-skladi.si</a>.

            <br>
            <br>
            <h5>Rezultati operacije</h5>
            Prleška spletna tržnica promovira kmetije in druge proizvajalce hrane ter izdelkov domače obrti na območju
            Prlekije, posredno pa tudi širom Slovenije, saj je promocija kmetij dostopna na spletu in objavljena v
            e-publikaciji. Povečala se bo neposredna prodaja končnim kupcem na območju Prlekije, saj dostava neposredno
            od ponudnikov do kupcev omogoča krajšo verigo, nižje stroške in konkurenčne cene. Koncept spletne tržnice
            omogoča tudi skupen nastop ponudnikov Prlekije na trgu, in to ne samo do končnih kupcev, temveč tudi do
            poslovnih
            strank in večjih porabnikov.


            <img class="mt-3" src="../../../assets/images/projekt.jpg" height="auto" width="100%"/></div>
    </div>
</template>

<script lang="">
export default {
name: "About",
        components: {},
        props: {},
        data() {
            return {}
        },
        mounted() {},
        methods: {},
        computed: {},
        filters: {},
        watch: {}
}









</script>

<style scoped>

</style>
